<template>
	<el-tabs :before-leave="go_tab">
		<el-tab-pane lazy label="报警中心">
			<madmin1 style="margin-bottom:10px" ref="main1" title="报警中心" table_name="Device_error" :table_show_header="true">
				<template #searchForm="{searchForm,show_more_search}">
					<el-form-item label="报警消息内容" prop="msg">
						<el-input v-model="searchForm.msg" />
					</el-form-item>
					<el-form-item label="报警事件" prop="alert_event">
						<el-input v-model="searchForm.alert_event" />
					</el-form-item>
					<el-form-item label="手机号" prop="sms_mobile" v-show="show_more_search">
						<el-input v-model="searchForm.sms_mobile" />
					</el-form-item>
					<el-form-item label="发生事件" prop="create_time">
						<el-date-picker v-model="searchForm.create_time" type="daterange" :picker-options="time_opt" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" align="right"></el-date-picker>
					</el-form-item>
					<el-form-item label="发送结果" prop="sms_is_suc" v-show="show_more_search">
						<el-radio-group v-model="searchForm.sms_is_suc">
							<el-radio-button label="">全部</el-radio-button>
							<el-radio-button label="1">成功</el-radio-button>
							<el-radio-button label="0">失败</el-radio-button>
						</el-radio-group>
					</el-form-item>
				</template>
				<!-- 列表 -->
				<template #table>
					<el-table-column label="报警编号" align="center" prop="id" width="80" />
					<el-table-column label="报警消息内容" prop="msg" />
					<el-table-column label="报警时间" align="center" prop="create_time" width="200" />
					<el-table-column label="报警类型" align="center" prop="alert_event" width="120" />
					<el-table-column label="实时值" align="center" prop="vals" width="120" />
					<el-table-column label="短信发送情况">
						<el-table-column label="手机号" align="center" prop="sms_mobile" width="200" />
						<el-table-column label="结果" align="center" prop="sms_is_suc" width="60" />
						<el-table-column label="备注" prop="sms_err_code" width="200" />
					</el-table-column>
				</template>
			</madmin1>
		</el-tab-pane>
	</el-tabs>
</template>
<script>
	import madmin1 from "../../components/madmin1.vue";
	import request from "../../plugins/axios";

	export default {
		data() {
			return {
				role: window.localStorage.getItem("role"),
				//datapicker的快速选择
				time_opt: {
					shortcuts: [
						{
							text: "最近一周",
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
								picker.$emit("pick", [start, end]);
							},
						},
						{
							text: "最近一个月",
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
								picker.$emit("pick", [start, end]);
							},
						},
						{
							text: "最近三个月",
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
								picker.$emit("pick", [start, end]);
							},
						},
					],
				},
				select_company: [],
			};
		},
		components: {
			madmin1,
		},
		mounted() {
			this.get_select_company();
		},
		methods: {
			search_btn(ref_name) {
				this.$refs[ref_name].onSearch();
			},
			del_btn(ref_name, id) {
				this.$refs[ref_name].onDelete(id);
			},
			edit_btn(ref_name, id) {
				this.$refs[ref_name].showWriteForm(id);
			},
			go_tab(activeName, oldActiveName) {},
			get_select_company() {
				request({
					url: "/admin/Company/select_list",
					data: {
						token: window.localStorage.getItem("token"),
					},
				}).then((ret) => {
					if (ret.code == 1) {
						this.select_company = ret.data;
					}
				});
			},
		},
	};
</script>